import clsx from "clsx";
import * as Yup from "yup";
import * as React from "react";
import { toast } from "react-hot-toast";
import { useController, useForm } from "react-hook-form";
import { Box, Typography, Divider } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./authenticate-candidate.module.scss";
import IMAGES from "../../assets/images/images";
import * as testService from "../../services/test";
import { CompanyLogoV1 } from "../../assets/images";
import GoogleAuth from "./GoogleAuth";
import LinkedInAuth from "./LinkedInAuth";

const schema = Yup.object({
  fullName: Yup.string().required("This field is required."),
  email: Yup.string()
    .email("Must be a valid email")
    .required("This field is required."),
});

const SendOtp = ({
  name,
  setName,
  email,
  setEmail,
  setStep,
  assessmentId,
  setInviteId,
  campaignId,
}) => {

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: name,
      email: email,
      acceptedTnC: false,
      keepPosted: false,
    },
    mode: "onChange",
    isDirty: true,
  });

  const { field: fieldEmail } = useController({ control, name: "email" });

  const sendOTP = async (values) => {
    try {
      const response = await testService.sendOtp({
        name: values.fullName,
        email: values.email,
        ...(assessmentId && { assessmentId }),
      });
      if (response.status === "COMPLETED")
        throw new Error("This assessment is already completed.");
      const _inviteId = response.hasInvited ? response.inviteId : null;
      toast.success("OTP send successfully");
      setName(values.fullName);
      setEmail(values.email);
      setInviteId(_inviteId);
      setStep("Otp_sent");
    } catch (err) {
      console.error(err);
      toast.error(err?.message || "Failed to send OTP");
    }
  };

  return (
    <div className={`${styles.takingTestWrap}`}>
      <div className={`mg-b-50 ${styles.logoWrapper}`}>
        <img
          className={`img-fluid`}
          src={CompanyLogoV1}
          alt="cyberforce-logo"
        />
      </div>
      <div className={`mg-b-28 ${styles.userLoginHeading}`}>
        <Typography
          variant={"headingSmall"}
          component={"h5"}
          className={"mg-b-4"}
        >
         Welcome to the Assessment Platform
        </Typography>
        <Typography variant={'headingSmall'} component={'p'} className={'mg-b-4'}>Let's get started</Typography>
        <Typography
          component={"p"}
          variant={"body1"}
          className={`font-weight-medium`}
        >
          {/* Please confirm who you are? */}
        </Typography>
      </div>
      <Box
        sx={{ display: "flex", gap: "8px" }}
        flexWrap={{ xs: "wrap", md: "nowrap" }}
      >
        <GoogleAuth assessmentId={assessmentId} campaignId={campaignId} />
        <LinkedInAuth assessmentId={assessmentId} campaignId={campaignId} />
      </Box>
      <Divider>
        <Typography
          textAlign="center"
          variant="caption"
          component="p"
          style={{ marginBlock: "10px" }}
        >
          OR
        </Typography>
      </Divider>
      <form onSubmit={handleSubmit(sendOTP)}>
        <div className={`mg-b-10`}>
          <label htmlFor={"full-name"} className={`form-label`}>
            Full name*
          </label>
          <input
            id="full-name"
            type="text"
            placeholder={"Enter full name"}
            className={`form-control form-control-md`}
            {...register("fullName")}
          />
          <p className={clsx("error", { "d-block": errors.fullName })}>
            {errors.fullName?.message}
          </p>
        </div>
        <div className={`mg-b-10`}>
          <label htmlFor={"email"} className={`form-label`}>
            Email*
          </label>
          <input
            type="email"
            id="email"
            placeholder={"Enter email"}
            className={`form-control form-control-md`}
            name={fieldEmail.name}
            value={fieldEmail.value}
            onChange={(e) =>
              fieldEmail.onChange(e.target.value.toLocaleLowerCase())
            }
            ref={fieldEmail.ref}
          />
          <p className={clsx("error", { "d-block": errors.email })}>
            {errors.email?.message}
          </p>
        </div>
        {/* <div className="custom-checkbox mg-b-20">
                    <input
                        className="custom-control-input"
                        type="checkbox"
                        id={`privacy-policy`}
                        {...register('acceptedTnC')}
                    />
                    <label
                        className="custom-control-label font-size-sm"
                        htmlFor={`privacy-policy`}
                    >I have read and I accept the <Link to={`/privacy-policy`}>Privacy Policy</Link></label>
                </div> */}
        {/* <div className="custom-checkbox">
                    <input
                        className="custom-control-input"
                        type="checkbox"
                        id={`keep-posted`}
                        {...register('keepPosted')}
                    />
                    <label
                        className="custom-control-label font-size-sm"
                        htmlFor={`keep-posted`}
                    >Keep me posted</label>
                </div> */}
        {/* <div className={`mg-t-20`}>
                    <button
                        className={'btn btn-purple btn-xl'}
                        type='submit'
                        disabled={!isValid || !isDirty || isSubmitting}
                    >
                        {isSubmitting ? 'Sending OTP' : 'Next'}
                        <img className={`mg-l-10`} src={IMAGES.ArrowNextWhite} alt="arrow-next" />
                    </button>
                </div> */}
        <div className={`mg-t-20`}>
          <button
            className={"btn btn-purple btn-xl"}
            type="submit"
            disabled={!isValid || !isDirty || isSubmitting}
            style={{ width: "100%" }}
          >
            {isSubmitting ? "Sending OTP" : "Next"}
            <img
              className={`mg-l-10`}
              src={IMAGES.ArrowNextWhite}
              alt="arrow-next"
            />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SendOtp;
