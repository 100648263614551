import * as React from 'react'
import { Typography } from "@mui/material";

import { CompanyLogoV1 } from '../../assets/images';
import { useAppAuth } from '../../contexts/AuthProvider';
import styles from './authenticate-candidate.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';


const VerifyOTP = ({ assessmentId, email, campaignId }) => {
    const appAuth = useAppAuth()
    const navigate = useNavigate()
    const [otp, setOtp] = React.useState('')
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const handleVerifyOTP = async (e) => {
        e.preventDefault()
        let errMsg = ''
        try{
            setLoading(true)
            const _payload = { email, otp, ...(assessmentId && { assessmentId }), ...(campaignId && { campaignId }) }
            await appAuth.authenticate({ provider: 'Local', payload: _payload })
        }catch(err){
            console.error(err)
        }
        setOtp('')
        setLoading(false)
        setError(errMsg)
    }

    return (
        <form onSubmit={handleVerifyOTP}>
        <div className={`${styles.takingTestWrap}`}>
            <div className={`mg-b-50 ${styles.logoWrapper}`}>
                <img className={`img-fluid`} src={CompanyLogoV1} alt="cyberforce-logo" />
            </div>
            <h5 className='text-danger text-center'>{error}</h5>
            <div className={`mg-b-28 ${styles.userLoginHeading}`}>
                <Typography variant={'headingMedium'} component={'h2'} className={'mg-b-4'}>OTP Verification</Typography>
                <Typography component={"p"} variant={"body1"} className={`font-weight-medium`}>
                    Thank you for registering with you. Please type the OTP as shared on your email {email}
                </Typography>
            </div>
            <div className={`mg-b-25`}>
                <label htmlFor={'otp'} className={`form-label`}>OTP</label>
                <input type="text" name="otp" id="otp" placeholder={'Enter OTP'} className={`form-control form-control-md`}
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    readOnly={loading}
                />
                {/* <Typography component={"p"} variant={"h5"} className={`mg-t-4 ${styles.timerCount}`}>00:35</Typography> */}
                {false && <Typography component={"p"} variant={"body1"} color={"error"} className={'mg-t-4'}>This field is required</Typography>}
            </div>
            <div className={`mg-t-20`}>
                <button
                    type='submit'
                    className={'btn btn-purple btn-xl'}
                    disabled={loading || otp.length < 8}
                    >
                    {loading ? 'Verifying...' : 'Submit'}
                </button>
            </div>
        </div>
        </form>
    )
}

export default VerifyOTP