
export const PAGE_PATH = {
    ROOT: '/',
    LINKEDIN: '/linkedin',
    HOME: '/home',
    LINK: {
        ROOT: '/link',
        PUBLIC: (id) => `/link/public/${id}`,
        INVITE: '/link/invite/',
        MOCK_INVITE: '/link/invite/mocking-bird',
    },
    TEMP: '/temp',
    ASSESS_INVITE: (id) => `/assess/${id}`,
    FAKE_LAB: (id) => `/fake-labs/${id}`,
    INVALID_CAMPAIGN: `/invalid-campaign`,
}