import * as React from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import { startAssessment, startCampaignAssessment, validateCampaign } from "../utils/helper";
import { candidate as candidateService, testService, assessmentService } from "../services";
import jwtDecode from "jwt-decode";

const ACTIONS = {
  SET_AUTH: "SET_AUTH",
  REMOVE_AUTH: "REMOVE_AUTH",
};

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  candidate: null,
  invites: null
};

const authServices = {
  Local: (values) => testService.verifyOtp(values),
  Google: (values) => candidateService.signInWithGoogle(values),
  LinkedIn: (values) => candidateService.signInWithLinkedIn(values),
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_AUTH:
      return {
        isInitialized: true,
        isAuthenticated: true,
        candidate: action.payload.candidate,
        invites: action.payload.invites,
      };
    case ACTIONS.REMOVE_AUTH:
      return {
        isInitialized: true,
        isAuthenticated: false,
        candidate: null,
        invites: null
      };
    default:
      return state;
  }
}

const context = React.createContext(initialState);

const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const fetchInvites = async () => {
    try {
      const _candidate = await candidateService.getProfile();
      const _invites = await assessmentService.getAllInvites();
      const _data = { candidate: _candidate, invites: _invites }
      dispatch({ type: ACTIONS.SET_AUTH, payload: _data });
    } catch (err) {
      Cookies.remove('authToken')
      dispatch({ type: ACTIONS.REMOVE_AUTH });
    }
  };

  const authenticate = async ({ provider, payload }) => {
    const { campaignId, assessmentId } = payload;
    try {
      const data = await authServices[provider](payload);
      Cookies.set("authToken", data.token)
      toast.success('sign in successfully.')
      const decode = await jwtDecode(data.token)
      const inviteId = decode.inviteId

      if(campaignId) {
        validateCampaign(assessmentId, campaignId);
        return;
      }

      if(payload.assessmentId && !inviteId){
        toast.error('Invalid Assessment Url')
        window.location.href = window.location.origin
        return
      }

      if(payload.assessmentId && inviteId) 
        localStorage.setItem(`aid_${payload.assessmentId}`, inviteId);
        localStorage.setItem(`iid_${inviteId}`, payload.assessmentId);

      if(payload?.campaignId) {
        validateCampaign(payload?.assessmentId, payload?.campaignId);
        return;
      }

      if(inviteId){
        startAssessment({ id: inviteId, token: data.token, target: '_self', campaignId: payload.campaignId })
        return
      }

      await fetchInvites();
    } catch (err) {
      Cookies.remove('authToken')
      dispatch({ type: ACTIONS.REMOVE_AUTH });
    }
  };

  const logOut = () => {
    Cookies.remove('authToken')
    dispatch({ type: ACTIONS.REMOVE_AUTH });
  };

  React.useEffect(() => {
    const __isStartLink = window.location.pathname.startsWith('/link');
    const __isAssessLink = window.location.pathname.startsWith('/assess');
    if(__isStartLink){
      console.log({ start: true })
    }else if(__isAssessLink){
      console.log({ assess: true })
    }else{
      fetchInvites()
    }
  }, []);

  return (
    <context.Provider value={{ ...state, authenticate, logOut }}>
      {children}
    </context.Provider>
  );
};

export const useAppAuth = () => React.useContext(context);

export default AuthProvider;
