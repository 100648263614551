import * as React from 'react'
import { Box, Card, CardContent, Typography, Container, Button } from "@mui/material";
import CampaignLinkInvalidImg from '../../assets/images/invalid-icon.svg';

import FooterLogoImg from '../../assets/images/footer-logo.png';
import AssessmentCompleteImg from '../../assets/images/assessment-complete.png';


const CampaignLinkInvalid = () => {

    return (
        <div className={`simple-wrapper`}>
            <Container maxWidth={"md"}>
                <Card className={`card-md`}>
                    <CardContent>
                        <div className={`invalid`}>
                            <img src={CampaignLinkInvalidImg} alt="Campaign Link Invalid" />
                            <div className='invalid-text'>
                                <h4>This is not a valid link</h4>
                                <div className='invalid-text-desc'>Please check the url and try again.</div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Container>
            <div className='wow-footer text-center'>
                <img src={FooterLogoImg} alt="" />
            </div>
        </div>
    )
}

export default CampaignLinkInvalid
