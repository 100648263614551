import Cookies from "js-cookie"
import { PAGE_PATH } from "../router/path"
import { isMobile } from "react-device-detect"
import jwtDecode from "jwt-decode";
import { getCampaignInviteToken } from "../services/campaign";
import { LOCAL_CAMPAIGN_STATUS } from "../config/constants";
import { getInvite } from "../services/assessment";

export const isEmpty = (obj) => {
    for (let k in obj) {
        return false
    }
    return true
}

export const getUserMediaVideoConfig = (args = {}) => {
    const { deviceId, height, width } = args
    
    const config = ((isMobile || deviceId || height || width) ? { 
        ...(isMobile && { facingMode: { exact: 'user' } }),
        ...(deviceId && { deviceId: { exact: deviceId } }),
        ...(height && { height }),
        ...(width && { width })
    } : true)

    return {video: config}
}

export const getUserMediaAudioConfig = (args = {}) => {
    const { deviceId } = args
    
    const config = { 
        ...(deviceId ? { deviceId: { exact: deviceId } } : true),
    }

    return {audio: config}
}

export const setTimeToCookie = (inviteData) => {
    const inviteId = inviteData._id
    const timeTotal = inviteData.assessment.time * 60
    const timer = inviteData.summary?.timer
    const timeLeft = Object.hasOwn((timer || {}), 'timeLeft') ? timer.timeLeft : timeTotal

    const { timeTotalKey, timeLeftKey } = getTimeCookieKeys(inviteId)

    removeUselessCookies(inviteId)
    Cookies.set(timeTotalKey, timeTotal)
    Cookies.set(timeLeftKey, timeLeft)
}

export const getTimeFromCookie = (inviteId) => {
    const { timeTotalKey, timeLeftKey } = getTimeCookieKeys(inviteId)
    return {
        timeTotal: Cookies.get(timeTotalKey),
        timeLeft: Cookies.get(timeLeftKey)
    }
}

export const updateTimeLeftToCookie = (inviteId, timeLeft) => {
    const { timeLeftKey } = getTimeCookieKeys(inviteId)
    Cookies.set(timeLeftKey, timeLeft)
}

function getTimeCookieKeys(inviteId) {
    const [timeTotalKey, timeLeftKey] = [`tt_${inviteId}`, `tl_${inviteId}`]
    return { timeTotalKey, timeLeftKey }
}

function removeUselessCookies(inviteId) {
    const existCookies = Cookies.get()
    const [timeTotalKey, timeLeftKey] = [`tt_${inviteId}`, `tl_${inviteId}`]
    for (let key in existCookies) {
        if (key.startsWith('tt') && key !== timeTotalKey) Cookies.remove(key)
        if (key.startsWith('tl') && key !== timeLeftKey) Cookies.remove(key)
    }
}

export const getTimeInSec = () => {
    return Math.trunc(Date.now() / 1000)
}

export const secondsToHMS = (seconds) => {
    const hours = Math.floor(seconds / 3600).toString();
    const minutes = Math.floor((seconds % 3600) / 60).toString();
    const remainingSeconds = String(seconds % 60);
    return (
        (Number(hours) > 0 ? `${hours.padStart(2, "0")}:` : "") +
        `${minutes.padStart(2, "0")}:${remainingSeconds.padStart(2, "0")}`
    );
}

export const getCampaignStatus = async (res) => {
    const DUPLICATE_CAMPAIGN_ERROR = "CANDIDATE_ALREADY_ENROLLED_IN_OTHER_CAMPAIGN_OF_JOB";

    try {
        const {startDate, expiryDate: endDate, usageLimit, usageCount} = res;
        const token = await getCampaignInviteToken(res?._id);
        if(token?.error) throw new Error(token?.error);
        Cookies.set("authToken", token?.token);
        const inviteRes = await getInvite();

        if(inviteRes?.error) return {status: LOCAL_CAMPAIGN_STATUS.INVALID}

        if(inviteRes?.startDate && new Date(inviteRes?.startDate) > new Date()) 
            return {status: LOCAL_CAMPAIGN_STATUS.NOT_STARTED, startDate: inviteRes?.startDate}

        const usageLimitExceeded = usageLimit <= usageCount;
        if(usageLimitExceeded) return {status: LOCAL_CAMPAIGN_STATUS.USAGE_LIMIT_EXCEEDED};

        const isExpired = new Date() > new Date(endDate);
        if (isExpired) return {status: LOCAL_CAMPAIGN_STATUS.EXPIRED};

        const isActive = res?.status !== LOCAL_CAMPAIGN_STATUS.PAUSED;
        if(!res || !isActive) return {status: LOCAL_CAMPAIGN_STATUS.INVALID};

        const hasStarted = new Date() >= new Date(startDate);
        if(!hasStarted) return {status: LOCAL_CAMPAIGN_STATUS.NOT_STARTED, startDate: startDate};

        return {status: LOCAL_CAMPAIGN_STATUS.VALID};
    } catch (error) {
        if(error?.message === DUPLICATE_CAMPAIGN_ERROR) 
            return {status: LOCAL_CAMPAIGN_STATUS.DUPLICATE_CAMPAIGN};
        return {status: LOCAL_CAMPAIGN_STATUS.INVALID};
    }
}

export const validateCampaign = (assessmentId, campaignId) => {
    //Function to re-direct to the campaign link landing page after a candidate has been authenticated
    const campaignParams = `campaignId=${campaignId}`
    window.open(`${window.location.origin}/link/public/${assessmentId}?${campaignParams}`, "_self");
}

export const startCampaignAssessment = async ({id, target = '_self'}) => {
    try {
        const res = await getCampaignInviteToken(id);
        if(res?.error) throw new Error(res?.error);
        const token = res?.token;
        const decode = jwtDecode(token);
        id = decode?.inviteId;

        startAssessment({id: id, token: token, target: target});
    } catch (error) {
            window.open(`${window.location.origin}${PAGE_PATH.INVALID_CAMPAIGN}`, target)
    }
}

export const startAssessment = async ({ id, token, target = '_blank'}) => {
    const __assessmentPath = PAGE_PATH.ASSESS_INVITE(id);
    if (token) Cookies.set('authToken', token, { path: __assessmentPath });
    window.open(`${window.location.origin}${__assessmentPath}`, target);
}


export const truncateStr = (str, num) => {
    if (str.length > num) {
        return str.slice(0, num) + "...";
    }
    return str;
}

export const extractTokenFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.href)
    return searchParams.get('token')
}