import * as React from 'react'
import Cookies from 'js-cookie';
import screenfull from 'screenfull'
import { Box, Card, CardContent, Typography, Container, Button } from "@mui/material";

import { PAGE_PATH } from '../../router/path'
import { fetchResult } from '../../redux/assessment/action';
import FooterLogoImg from '../../assets/images/footer-logo.png';
import AssessmentCompleteImg from '../../assets/images/assessment-complete.png';
import { useSelector } from 'react-redux';


const AssessmentLinkInvalid = () => {

    const invite = useSelector((state) => state.assessment.__data)
    const redirectUrl = invite?.jobDetail?.assessmentRedirectUrl || window.location.origin

    return (
        <div className={`wow-wrapper`}>
            <Container maxWidth={"md"}>
                <Card className={`card-md card-snow`}>
                    <CardContent>
                        <div className={`wow-card d-flex flex-wrap align-items-center congratulations-cards`}>
                            <div className='wow-card-left'><img src={AssessmentCompleteImg} alt="Assessment Complete" /></div>
                            <div className='wow-card-right'>
                                <Typography variant={'h3'}>Oops!</Typography>
                                <Typography className={`mg-t-10 `}>Something went wrong!</Typography>
                                <Typography className={`mg-t-10 `}>Please contact the administrator for further assistance</Typography>
                            </div>
                        </div>
                        <Box sx={{ textAlign: 'center', paddingTop: '15px' }}>
                            <Button variant='contained' href={redirectUrl}>Go To Home</Button>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            <div className='wow-footer text-center'>
                <img src={FooterLogoImg} alt="" />
            </div>
        </div>
    )
}

export default AssessmentLinkInvalid
