import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useLinkedIn } from "react-linkedin-login-oauth2";

import { LINKED_IN_OAUTH } from "../../config";
import IMAGES from "../../assets/images/images";
import { useAppAuth } from "../../contexts/AuthProvider";

const SocialButton = styled(Button)(() => ({
  height: "44px",
  whiteSpace: 'nowrap',
  fontWeight: '400',
  width: '100%',
}));

const LinkedInAuth = ({ assessmentId, campaignId}) => {
  const appAuth = useAppAuth()
  const { linkedInLogin } = useLinkedIn({
    scope: LINKED_IN_OAUTH.SCOPE,
    clientId: LINKED_IN_OAUTH.CLIENT_ID,
    redirectUri: LINKED_IN_OAUTH.REDIRECT_URI,
    onSuccess: async (code) => {
      try {
        await appAuth.authenticate({ 
          provider: 'LinkedIn', 
          payload: { code, ...(assessmentId && { assessmentId }), ...(campaignId && { campaignId }) } 
        })
      } catch (err) {
        console.log(err);
        toast(err?.data?.message, { type: "error" });
      }
    },
    onError: (err) => {
      toast(err.message, { type: "error" });
    },
  });

  return (
    <SocialButton
      color={"secondary"}
      variant={"outlined"}
      className={`btn-lg w-full text-dark`}
      onClick={linkedInLogin }
      style={{ whiteSpace: 'nowrap', fontWeight: '400', width: '100%' }}
    >
      <img className={`img-fluid`} src={IMAGES.LinkedInLogo} alt="linkedIn" style={{ marginRight: '5px' }} />
      Continue with LinkedIn
    </SocialButton>
  );
}

export default LinkedInAuth;
