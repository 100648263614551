import * as React from "react";
import Cookies from "js-cookie";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PAGE_PATH } from "../router/path";
import { assessmentService } from "../services";
import { setTimeToCookie } from "../utils/helper";

import * as flowControlActions from "../redux/flowControl/action";
import * as proctoringActions from "../redux/proctoring/action";
import * as assessmentActions from "../redux/assessment/action";

import PageLoader from "../Components/Loader/PageLoader";
import AssessmentLinkOops from "../pages/Error/AssessmentLinkOops";
import { invitationSchema } from "../utils/invitation-schema";

const sanitizeInviteData = (inviteData) => {
  inviteData.assessment.title = inviteData.assessment.title ?? ''
  inviteData.assessment.time = inviteData.assessment.time ?? 0
  inviteData.assessment.logo = inviteData.assessment.logo ?? ''
  inviteData.assessment.antiCheatingTrackingTypes = inviteData.assessment.antiCheatingTrackingTypes ?? []
  inviteData.assessment.candidatePhotCaptureTimeInSeconds = inviteData.assessment?.candidatePhotCaptureTimeInSeconds > 0 ? (inviteData.assessment.candidatePhotCaptureTimeInSeconds*1000) : 10*1000
  // if(inviteData.job){
  //   inviteData.job._id = inviteData.job._id ?? 'logo_id'
  //   inviteData.job.logo = inviteData.job.logo ?? ''
  // }
  inviteData.tests.forEach((test) => {
     test.logo = test.logo ?? ''
  })
  let totalLabTime = 0
  let totalTestTime = inviteData.assessment.time
  inviteData.assessment.labsDetails = inviteData.assessment.labsDetails ?? []
  inviteData.assessment.labArrOfObj = inviteData.assessment.labsDetails
  inviteData.assessment.labsDetails.forEach((lab) => { totalLabTime += Number(lab?.durationInMinutes) })
  inviteData.labs = inviteData.assessment.labsDetails.map(({ _id }) => _id)
  inviteData.assessment.totalTime = totalTestTime + totalLabTime
  return inviteData
}

const InviteGuard = ({ children }) => {
  const { id } = useParams()
  const dispatch  = useDispatch()
  const assessment = useSelector((state) => state.assessment)
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  if(token){
    Cookies.set('authToken', token)
    Cookies.set('authToken', token, { path: PAGE_PATH.ASSESS_INVITE(id) })
  }

  React.useEffect(() => {
    async function fetchInvite() {
      try {
        const response = await assessmentService.getInvite();
        const sanitizedResponse = sanitizeInviteData(response)
        // const inviteData = await invitationSchema.validate(sanitizedResponse, { stripUnknown: true })
        const inviteData = sanitizedResponse;
        const flowControl = inviteData.summary.flowControl;
        await dispatch(assessmentActions.invitationSuccess(inviteData))
        dispatch(proctoringActions.initializeProctoring(inviteData));
        if (flowControl?.assessment?.__initialized) {
          const { assessment, qualifyingWindow } = flowControl
          dispatch(flowControlActions.updateAV2({ assessment, qualifyingWindow }));
          dispatch(flowControlActions.updateTV2(flowControl.currentTest));
        } else {
          dispatch(flowControlActions.initializeAV2(inviteData));
        }
        setTimeToCookie(inviteData);
      } catch (err) {
        console.error(err);
        Cookies.remove('authToken', { path: PAGE_PATH.ASSESS_INVITE(id) })
        const aid = localStorage.getItem(`iid_${id}`)
        localStorage.removeItem(`iid_${id}`)
        localStorage.removeItem(`aid_${aid}`)
        dispatch(assessmentActions.invitationError(err))
      }
    }
    fetchInvite();
  }, []);

  if (!assessment.__initialized) return <PageLoader />;

  if (!assessment.__data){
    return <AssessmentLinkOops />
  }


  return children;
};

export default InviteGuard;
